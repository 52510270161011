<template>
	<div class="body-block" :class="{ 'sidebar-xs': sidebarHide, 'sidebar-mobile-main': sidebarMobile }">
		<div class="navbar navbar-expand-md navbar-dark">
			<div class="navbar topbar-logo navbar-brand">
				<img class="logo" src="/assets/images/logo.png" />
			</div>
			<div class="d-md-none">
				<button class="navbar-toggler sidebar-mobile-main-toggle" type="button" @click="onChangeSidebarMobile">
					<i class="icon icon-menu"></i>
				</button>
				<button class="navbar-toggler" type="button" @click="onChangeNavbarMobileShow">
					<i :class="{ 'icon icon-chevron-up': navbarMobileShow, 'icon icon-chevron-down': !navbarMobileShow }"></i>
				</button>
			</div>

			<div class="collapse navbar-collapse" :class="{ show: navbarMobileShow }">
				<ul class="navbar-nav">
					<li class="nav-item">
						<a href="#" class="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block" @click="onChangeSidebar">
							<i class="icon icon-menu"></i>
						</a>
					</li>
				</ul>

				<span class="badge bg-success ml-md-3 mr-md-auto" v-if="online">Online</span>
				<span class="badge bg-danger ml-md-3 mr-md-auto" v-else>Offline</span>

				<!-- УЗ. Отделение -->
				<div v-if="availabeRole(['admin', 'chief_medical', 'manager'])" class="mx-1">
					<span class="mr-md-auto">{{ userProfile.employee?.organization }}</span>
				</div>
				<div v-if="config.title" class="mx-1">
					<span class="mr-md-auto">{{ config.title }}</span>
				</div>

				<ul class="navbar-nav">
					<Messages />
					<User />
				</ul>
			</div>
		</div>
		<!-- /main navbar -->

		<!-- Page content -->
		<div class="page-content">
			<!-- Main sidebar -->
			<Sidebar />
			<!-- /main sidebar -->

			<!-- Main content -->
			<div class="content-wrapper">
				<!-- Page header -->
				<div class="page-header page-header-light">
					<div class="page-header-content header-elements-md-inline" v-if="pageTitle">
						<div class="page-title d-flex">
							<h4>{{ pageTitle }}</h4>

							<a href="#" class="header-elements-toggle text-default d-md-none">
								<i class="icon-more"></i>
							</a>
						</div>
					</div>

					<BreadCrumb />
				</div>
				<!-- /page header -->

				<!-- Content area -->
				<slot />
				<!-- /content area -->

				<!-- Footer -->
				<Footer />
				<!-- /footer -->
			</div>
			<!-- /main content -->
		</div>
	</div>
</template>

<script>


import { computed, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';

import { sidebarHide, sidebarMobile, navbarMobileShow, pageTitle } from "./layouts";

import Sidebar from "./Sidebar";
import BreadCrumb from "./BreadCrumb";
import Messages from "./Dropdown/Messages";
import User from "./Dropdown/User";
import Footer from "./Footer";

import { availabeRole } from '@/helpers/access';

import { profile } from '@/api/users';
import { config } from '@/config';

export default {
	name: "Default",

	components: {
		Sidebar, Messages, User, BreadCrumb, Footer
	},

	setup() {
		const { state, dispatch } = useStore();


		const userProfile = reactive({
			...JSON.parse(localStorage.getItem('userProfile')) || {}
		});

		onMounted(async () => {
			if (state.user.id) {
				const response = await profile(state.user.id);

				Object.assign(userProfile, response);
				localStorage.setItem('userProfile', JSON.stringify(userProfile));
			}
		})

		return {
			sidebarHide,
			sidebarMobile,
			navbarMobileShow,
			pageTitle,
			config,
			state,
			user: computed(() => state.user),
			userProfile,
			onLogout: () => dispatch('logout'),
			availabeRole,

			onChangeSidebar: () => sidebarHide.value = !sidebarHide.value,
			onChangeSidebarMobile: () => sidebarMobile.value = !sidebarMobile.value,
			onChangeNavbarMobileShow: () => navbarMobileShow.value = !navbarMobileShow.value,
			online: computed(() => state.online)
		}
	}
}
</script>

<style scoped>
.text-semibold {
	font-weight: 500;
}

.topbar-logo {
	font-size: 20px;
	max-height: 25px;
	border: 0;
	display: inline-block;
	padding-top: 0.5rem;
	padding-left: 0rem;
	padding-bottom: 1rem;
	margin-right: -2.2rem;
	line-height: inherit;
	white-space: nowrap;
}

.logo {
	width: 9.5rem;
	height: 2rem;
}
</style>
