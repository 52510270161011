import dayjs from "dayjs";
import 'dayjs/locale/ru';

import 'mosha-vue-toastify/dist/style.css';
import "@/assets/scss/styles.scss";

import { createApp } from 'vue';

import stateStore from '@/store';

import App from './App.vue';
import './registerServiceWorker'
import router from '@/router';

import VFocus from "@/directives/VFocus";
import VClickaway from "@/directives/VClickaway";

import { Tabs, Tab } from "@/components/Tabs"
import Card from "@/components/Card.vue";
import DBForm from "@/components/DB/DBForm/index.vue";
import DBPanel from "@/components/DB/DBPanel/index.vue";
import DBEdit from "@/components/DB/DBEdit/index.vue";
import DBEditor from "@/components/DB/DBEditor/index.vue";
import DBGrid from "@/components/DB/DBGrid/index.vue";
import DBTree from "@/components/DB/DBTree/index.vue";
import DBTable from "@/components/DB/DBTable/index.vue";
import DBCaption from "@/components/DB/DBCaption/index.vue";
import DBLabel from "@/components/DB/DBLabel/index.vue";
import UploadFiles from "@/components/UploadFiles.vue";
import DBMultiEdit from '@/components/DB/DBMultiEdit/index.vue';
import DBQuestionary from '@/components/DB/DBQuestionary/index.vue';
import { createPinia } from 'pinia';
import '@/core/wsclient';

dayjs.locale('ru');

const app = createApp(App);

// Используем плагины и библиотеки
app.use(stateStore)
   .use(router);

// Регистрируем глобальные компоненты
app.component("Tabs", Tabs)
   .component("Tab", Tab)
   .component("DBForm", DBForm)
   .component("DBPanel", DBPanel)
   .component("DBEdit", DBEdit)
   .component("DBEditor", DBEditor)
   .component("DBGrid", DBGrid)
   .component("DBTree", DBTree)
   .component("DBTable", DBTable)
   .component("DBCaption", DBCaption)
   .component("DBLabel", DBLabel)
   .component("UploadFiles", UploadFiles)
   .component("DBMultiEdit", DBMultiEdit)
   .component("DBQuestionary", DBQuestionary)
   .component("Card", Card);

// Регистрируем директивы
app.directive('focus', VFocus)
   .directive('clickaway', VClickaway);

// Создаем и используем Pinia
const pinia = createPinia();
app.use(pinia);

// Монтируем приложение
app.mount('#app');